import { GlobeAmericasIcon } from "@heroicons/vue/20/solid";

export const theRoleOfTheMap = {
  order: 0,
  name: "The role of the map",
  subcategory: "The map",
  icon: GlobeAmericasIcon,
  iconColor: "text-cyan-400",
  markdown: `# The role of the map
  Real estate is about **where** more than anything else. Because of this, a map is part of every primary view in Tower Hunt. Unsurprisingly, the map can show you where things are so you can take action. Tower Hunt's map is different in one important way: to reveal data, you have to explore.

  {% callout type="tip" %}
  **Tip:** Learn more about {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %} to personalize your Tower Hunt experience.
  {% /callout %}

  ## Big Map

  When you first visit Tower Hunt, a map fills the primary panel &mdash; this is the Big Map. Use it to quickly locate the data you need. It is your "home" page, reachable via the Tower Hunt logo in the upper-left corner.

  ![Big Map screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink articleId="search" %}search{% /inlineRouterLink %}, {% inlineRouterLink articleId="create-a-property" %}create a new property{% /inlineRouterLink %}, {% inlineRouterLink articleId="find-nearby-companies" %}find nearby companies{% /inlineRouterLink %}, and {% inlineRouterLink articleId="the-role-of-the-analyze-tab" %}analyze property data{% /inlineRouterLink %} from Big Map.
  {% /callout %}

  ## Mini Map

  Company, contact, and property pages feature a map in the lower-left corner &mdash; this is the Mini Map. It orients you to the location of the focal record(s) in the primary view.

  ![Mini Map screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="Select a property" %}select a property{% /inlineRouterLink %} and {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="View multiple properties" %}view multiple properties{% /inlineRouterLink %} from Mini Map.
  {% /callout %}

  ## Compact Map

  When you want to use the map without leaving your primary view, the Mini Map can be expanded to fill the width of the primary panel &mdash; this is the Compact Map.

  ![Compact Map screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink articleId="search" %}search{% /inlineRouterLink %}, {% inlineRouterLink articleId="create-a-property" %}create a new property{% /inlineRouterLink %}, {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="Select a property" %}select a property{% /inlineRouterLink %} and {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="View multiple properties" %}view multiple properties{% /inlineRouterLink %} from Compact Map.
  {% /callout %}`,
};
